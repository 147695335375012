/**************************************************************************
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 **************************************************************************/
require('./bootstrap');


import Axios from 'axios';
// import Toastr from 'toastr';
// import './/../node_modules/toastr/build/toastr.css'; //You need style and css loader installed and set

// Toastr.options.timeout = 2000; //Change the settings

// Toastr.info('This works!');

//  import $ from 'jquery';
//  window.$ = window.jQuery = $;

// window.Vue = require('vue');

// import Swal from 'sweetalert2/src/sweetalert2.js'

import Swal from 'sweetalert2';
// const Swal = require('sweetalert2')

import Vue from 'vue';
// Vue.config.silent; 

// import mymodels from './components/MyModelsComponent.vue';
// Vue.component('my-models', mymodels); 




// App 
const app = new Vue({
    // name: 'gps_app',
    el: '#app',
    data: {
        email:'',
        password:'',
        bell:'',
        company:'',
        applicationServerPublicKey :'BO4HdgreOXSXCbtSb-918uUJmQE011v24e0KFaf4Qacb-Q7dgTjKIH4mdJ-Melxyw6DdLrZlmBoWSWnSu-X4cFY',
        user: $('#user_id').val(),
        company: $('#company_id').val(),
        deferredInstallPrompt: null,
        serviceWorker: null
    },
    directives: {
        focus: {
          inserted: function (el) {
            el.focus()
          }
        }
    },
    mounted: function(){
        
        // VALIDATE USER LOGIN
        if (document.getElementById('user_id')) {
            // if (typeof this.user !== 'undefined') {
            this.user = $('#user_id').val(); 

            // VALIDATE NOTIFICATIONS SUPPORT AND PERMISSIONS
            if (!'Notification' in window) {
                // Notifications aren't supported
                Swal.fire({
                    position: "top",
                    toast:true,
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 2000,
                    text: 'El navegador no posee soporte para notificaciones!',
                    showClass: {
                        popup: 'animated fadeIn',
                        backdrop: ''
                      },
                      hideClass: {
                        popup: 'animated fadeOut',
                        backdrop: ''
                    }
                });
                
            } else{
              

                // VALIDATE SERVICE WORKER SUPPORT
                if ('serviceWorker' in navigator && 'PushManager' in window) {
                    // From your client pages:
                    const channel = new BroadcastChannel('sw-messages-' + this.user);

                    channel.addEventListener('message', event => {
                        console.log('Receiving Event from WebPush', event.data.data);
                        console.log('playing audio');
                        var audio = new Audio('sounds/notification_sound.mp3'); 
                        audio.play();

                        if (event != '') {
                            this.refreshingBell('new');
                            this.addNotification(event.data.data);
                        }
        
                    });

                    // Service Worker and Push is supported
                    this.registration(); 
                    // VALIDATE NOTIFICATION PERMISSION
                    this.checkPermissions();
                    
                }else{

                    // Service Worker and Push is not supported
                    Swal.fire({
                        icon: 'warning',
                        title: 'Advertencia',
                        html: 'El navegador que está utilizando no tiene soporte para trabajos sin conexión',
                    });

                }    
                
                // LISTEN LARAVEL ECHO CHANNEL FROM PUSHER EVENTS FOR USER
                // window.Echo.channel(`user.${this.user}`)
                //     .listen('EventCreated', (e) => {
                //         console.log('Listening EventCreated on Laravel Echo Channel...');
                //         this.NotifyDesk(e)
                // });
                            
            }
            
            

        }else{
            
            navigator.serviceWorker.getRegistrations()
            .then(function(registrations) {
                // console.log(registrations);
                // console.log('Removing ServiceWorkers...');
                for(let registration of registrations) {
                    registration.unregister();
                }
            });
            console.log('************* YOU NEED LOGIN *************');
        }

          
        // serviceWorker.postMessage(['Testing my worker wey'])
        // if(first != '') {
        //  myWorker.postMessage([first]);
        //     console.log('Message posted to worker');

        //     myWorker.onmessage = function(e) {
        //         console.log('Message received from worker');
        //         console.log(e);
        //     }
        // }

    },
    methods: {

        registration(){


            console.log('Entering for registration...');

            window.addEventListener('appinstalled', () => {
                // Hide the app-provided install promotion
                // hideInstallPromotion();

                // Clear the deferredPrompt so it can be garbage collected
                // deferredPrompt = null;

                // Optionally, send analytics event to indicate successful install
                console.log('PWA was installed');
            });


            // Load and get registration
            // console.log('Geting registration!' , navigator.serviceWorker);

            navigator.serviceWorker.register('/worker.js').then(function(registration) {
                // Registration was successful
                console.log('ServiceWorker registration successful with scope: ', registration.scope);

                if (registration.installing) {
                    // Service Worker is Installing
                    console.log('Service Worker is Installing...');
                    app.serviceWorker = registration.installing;
                }

                if (registration.waiting) {
                    // Service Worker is Installing
                    console.log('Service Worker is Waiting...');
                    app.serviceWorker = registration.waiting;
                }

                if (registration.active) {
                    // Service Worker is Active
                    console.log('Service Worker is active...');
                    app.serviceWorker = registration.active;

                }                   
                
                if (app.serviceWorker) {

                    app.getServerSubscription();      
    
                    if (app.serviceWorker.state === 'activated') {
                        console.log('Activated');
                        app.subscribeUser(registration);
                    }


                    // if (app._data.deferredInstallPrompt == null) {
                    //     app.installApp();
                    // }else{
                    //     console.log(app._data.deferredInstallPrompt);
                    // }




                    registration.addEventListener('updatefound', () => {
                        // A wild service worker has appeared in reg.installing!
                        const newWorker = registration.installing;
                        console.log('New Service Wordker (updateFound): ' + newWorker.state);

                        // "installing" - the install event has fired, but not yet complete
                        // "installed"  - install complete
                        // "activating" - the activate event has fired, but not yet complete
                        // "activated"  - fully active
                        // "redundant"  - discarded. Either failed install, or it's been
                    
                        newWorker.addEventListener('statechange', function(e) {

                            console.log('statechange: ' + e.target.state);

                            if (e.target.state === 'activated') {
                                console.log('ServiceWorker Ready to Subscription!');
                                registration.pushManager.getSubscription().then(function(subscription) {

                                    if (subscription === null) {
                                        // Update UI to ask user to register for Push
                                        console.log('Usuario No Suscrito to push service!');
                                        app.subscribeUser(registration);
                                        
                                    } else {

                                        // We have a subscription, update the database
                                        console.log('We Ha a Subscription!!!');
                                        console.log('Subscription object: ', subscription);
                                        console.log('Subscription Endpoint: ', subscription.endpoint);
                                        console.log('Subscription getKey("p256dh"): ', subscription.getKey("p256dh"));
                                        console.log('Subscription getKey("auth"): ', subscription.getKey("auth"));
                                        console.log('Subscription to Json');
                                        console.log(subscription.toJSON());

                                    }
                                });

                            }

                        });
                    });
                    
                }
                    
            }, function(err) {
            // registration failed :(
            console.log('ServiceWorker registration failed: ', err);
            });


        },

        ValidateLogin($event) {

            $event.preventDefault();

            if ($event) {

                var email = this.email;
                var password = this.password;

                Axios.post('validate', {  
                    email: this.email,
                    password: this.password
                })

                .then(function (response) {

                    localStorage.setItem('api_token', response.data.access_token);

                    if (response.data.access_token != undefined) {
                        
                        Swal.fire({

                            title: response.data.action, 
                            text: response.data.response, 
                            icon: 'success',
                            timer: 1000,
                            showClass: {
                                popup: 'animated zoomIn',
                                backdrop: ''
                              },
                              hideClass: {
                                popup: 'animated fadeOut',
                                backdrop: ''
                            }
    
                        }).then(function (data) {
    
                            // TOAST ALERT WITH REDIRENTING TOO...
                            Swal.fire({
                                position: "top",
                                toast:true,
                                showCancelButton: false,
                                showConfirmButton: false,
                                text: 'Será redireccionado en breves momentos...',
                                showClass: {
                                    popup: 'animated fadeIn',
                                    backdrop: ''
                                  },
                                  hideClass: {
                                    popup: 'animated fadeOut',
                                    backdrop: ''
                                }
                            })
    
                            setTimeout(function(){
                                window.location.href = response.data.url
                            }, 500);
    
                        })
    

                    } 
                    
                    else {
                        
                        Swal.fire({
                            title: 'Advertencia', 
                            text: response.data.errors.login,
                            icon: 'error',
                            // backdrop: "linear-gradient(rgba(255, 255, 255, 0.4), rgba(176, 111, 148, 0.4))",
                            // backdrop: "rgba(176, 111, 148, 0.4)",
                            confirmButtonClass: 'btn btn-primary',
                            buttonsStyling: false,
                            showClass: {
                                popup: 'animated zoomIn', 
                              },
                              hideClass: {
                                popup: 'animated fadeOut',
                            }
    
                        }); 

                        // setTimeout(function(){
                        //     location.reload();
                        // }, 10000);

                    }


                })
                .catch(function (error) {

                    Swal.fire({
                        title: 'Error de Conexión', 
                        text: 'Por favor, verifique el acceso a internet y el ingreso de los datos',
                        icon: 'error',
                        background: "white",
                        // timer: 5000,
                        showClass: {
                            // tada, flipInX, bounceIn, bounceOut, fadeOut, fadeIn, zoomIn, zoomOut, slideInRight
                            // slideOutRight, slideInLeft, slideOutLeft
                            popup: 'animated shake', 
                            backdrop: ''
                          },
                          hideClass: {
                            popup: 'animated fadeOut',
                            backdrop: ''
                        }

                    }); 

                    setTimeout(function(){
                        location.reload();
                    }, 5000);


                });
                
            }
        },

        ShowAlert(id) {


            Axios.get('api/showalert/' + id).then(function (json) {

                console.log(json.data);

                var title = '';

                if (json.data.title !== null) {
                    title = json.data.title;
                }else{
                    title = 'SIN TITULO';
                }

                $('#' + json.data.id).remove();
                
                Swal.fire({
                    // title: '<div class="row text-center"><span class="col-12"><i class="icon-bell feather col-12 m-1"></i></span></div><div class="row text-center"><div class="col-12"><span class="col-12">' + data.title + '</span></div></div>',
                    title: title,
                    html: '<div class="col-12"><p class="text-justify my-1">' + json.data.observation + '</p></div>',
                    // html: '<div class="col-12"><p class="text-justify my-1">' + this.capitalize(json.data.observation) + '</p></div>',
                    // html: '<img class="img-fluid img-thumbnail" src="images/logo-black.png">',
                    icon: 'success',
                    footer: `<div class="card col-12 text-center">    
                    <div class="card-body">
                    <span class="text-dark">${json.data.created_by}</span><br/>
                    <span class="text-primary">${json.data.created_at}</span>
                    </div>
                    <div/>`,
                    
                    // 'Creado por : ' +  json.created_by + ' <br/> <span class="text-primary"> ' +  json.created_at + ' </span>',
                    // showConfirmButton: false,
                    confirmButtonText: 'Aceptar <i class="feather icon-thumbs-up"></i>',
                    confirmButtonClass: 'btn btn-primary',
                    buttonsStyling: false,
                    // closeOnConfirm: false,
                    // closeOnCancel: false

                }).then(function(e) {
                    
                    app.refreshingBell('read');

                    // $('#notifications').text(event_total + ' Notificaciones')
                })
                
            }).catch(function (response) {
                
                console.log(response);
                
            });
            
            
        
        },

        installApp:function(){


            window.addEventListener('beforeinstallprompt', app.saveBeforeInstallPromptEvent);

            // let deferredPrompt;

            // window.addEventListener('beforeinstallprompt', (e) => {
            // // Prevent the mini-infobar from appearing on mobile
            // e.preventDefault();
            // // Stash the event so it can be triggered later.
            // deferredPrompt = e;
            // // Update UI notify the user they can install the PWA
            // showInstallPromotion();
            // // Optionally, send analytics event that PWA install promo was shown.
            // console.log(`'beforeinstallprompt' event was fired.`);
            // });

        },

        saveBeforeInstallPromptEvent(evt) {

            this.deferredInstallPrompt = evt;
            this.deferredInstallPrompt.preventDefault(); 
            
            const btnInstallApp = document.getElementById('install');

            if(btnInstallApp) { 

                btnInstallApp.addEventListener('change', e => {

                    this.deferredInstallPrompt.prompt()
                    this.deferredInstallPrompt.userChoice
                    
                    .then(choiceResult => {
                        if(choiceResult.outcome === 'accepted') {
                            console.log('user accepted A2HS prompt');
                            $('#install').prop('disabled', true);
                        } else {
                            console.log('user dismissed A2HS prompt');
                            $('#install').prop('checked', false);
                        }
                        this.deferredInstallPrompt = null
                    });

                });

            }else{

                console.log('Ir a Perfil para instalar');
            
            }

            
            // this.deferredInstallPrompt.prompt()
            // this.deferredInstallPrompt.userChoice
            // .then(choiceResult => {
            //     if(choiceResult.outcome === 'accepted') {
            //     console.log('user accepted A2HS prompt')
            //     } else {
            //     console.log('user dismissed A2HS prompt')
            //     }
            //     this.deferredInstallPrompt = null
            // })
            
            
        },

        Installer(root) {

            let promptEvent;
          
            const install = function(e) {
              if(promptEvent) {
                promptEvent.prompt();
                promptEvent.userChoice
                  .then(function(choiceResult) {
                    // The user actioned the prompt (good or bad).
                    // good is handled in
                    promptEvent = null;
                    ga('send', 'event', 'install', choiceResult);
                    root.classList.remove('available');
                  })
                  .catch(function(installError) {
                    // Boo. update the UI.
                    promptEvent = null;
                    ga('send', 'event', 'install', 'errored');
                    root.classList.remove('available');
                  });
              }
            };
          
            const installed = function(e) {
              promptEvent = null;
              // This fires after onbeforinstallprompt OR after manual add to homescreen.
              ga('send', 'event', 'install', 'installed');
              root.classList.remove('available');
            };
          
            const beforeinstallprompt = function(e) {
              promptEvent = e;
              promptEvent.preventDefault();
              ga('send', 'event', 'install', 'available');
              root.classList.add('available');
              return false;
            };
          
            window.addEventListener('beforeinstallprompt', beforeinstallprompt);
            window.addEventListener('appinstalled', installed);
          
            root.addEventListener('click', install.bind(this));
            root.addEventListener('touchend', install.bind(this));
        },

        checkPermissions(){

            let btnNotifyApp = document.getElementById('notifyme');

            if (btnNotifyApp) {

                console.log('btnNotify');
                console.log(btnNotifyApp);

                if (Notification.permission === 'granted') {

                    $('#notifyme').prop('checked', true);
                    this.registration(); 

                }else{
                    
                    $('#install').prop('checked', false).prop('disabled', true);
                    
                    btnNotifyApp.addEventListener('change', () => {
                   
                        Notification.requestPermission(function(response) {

                            console.log(response);

                            if (response === 'denied') {
                                $('#notifyme').prop('checked', false);
                                $('#install').prop('checked', false).prop('disabled', true);
                            }
                            if (response === 'granted') {
                                $('#notifyme').prop('disabled', true).prop('checked', true);
                                $('#install').prop('checked', false).prop('disabled', false);
                                app.registration();
                            }

                        }).catch(function(e){
                            console.log(e);
                        })
                                    

                    });

                }                       


            }else{
                console.log('Entra sino btnNotifyApp on checkPermissions');
            }

        },

        getServerSubscription(){
            console.log('Entering on getServerSubscription...!');
        },

        subscribeUser(registration){

            console.log('Subcribing User...');

            let publicKey = this.urlBase64ToUint8Array(this.applicationServerPublicKey);

            var options = {
                userVisibleOnly: true,
                applicationServerKey: publicKey
            };
            

            registration.pushManager.subscribe(options).then(
                function(pushSubscription) {
                
                    console.log('Received PushSubscription: ');

                    let string = JSON.stringify(pushSubscription);
                    let subscription = JSON.parse(string);
                    let endpoint = subscription.endpoint;
                    let p256dh = subscription['keys'].p256dh;
                    let auth = subscription['keys'].auth;

                    // console.log(pushSubscription.endpoint);
                    // console.log(pushSubscription.getKey("p256dh"));
                    // console.log(pushSubscription.getKey("auth"));
            
                    app.storePushSubscription(endpoint, p256dh, auth);
                    
                    // return pushSubscription;
                    // The push subscription details needed by the application
                    // server are now available, and can be sent to it using,
                    // for example, an XMLHttpRequest.
            
                }, function(error) {
            
                    // During development it often helps to log errors to the
                    // console. In a production environment it might make sense to
                    // also report information about errors back to the
                    // application server.
                    console.log(error);
        
                }
            ).catch(function(e){
                console.log(e);
            });


            return registration;

        },

        urlBase64ToUint8Array(base64String) {
            var padding = '='.repeat((4 - base64String.length % 4) % 4);
            var base64 = (base64String + padding)
                .replace(/\-/g, '+')
                .replace(/_/g, '/');
          
            var rawData = self.atob(base64);
            var outputArray = new Uint8Array(rawData.length);
          
            for (var i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;
        },

        storePushSubscription(end, p256, token){

            Axios.post('api/subscriptions', {
                // subscription
                endpoint: end,
                p256dh: p256,
                auth: token,
                user: this.user,
            })
            .then(function(response){

                console.log('Notificaciones Habilitadas');
                // console.log(response);
                app.installApp();
                // Swal.fire({
                //     icon:'success',
                //     title:'Notificaciones Habilitadas',
                //     confirmButtonClass: 'btn btn-primary',
                //     buttonsStyling: false,
                //     html:'Las notificaciones han sido habilitadas correctamente',
                // });

            }).catch(function(e){
                console.log(e);
            })

        },

        NotifyDesk(e){
            
            console.log('NotifyDesk');
            // console.log(e);
            // this.printer(e);
            this.refreshingBell('new');

            var options = {
                image: 'images/login.jpg',
                body: e.observation,
                icon: 'images/logo200.jpg',
                badge: 'images/icons/send.svg',
              
                // tag: e.title,
                // data: e,
                // timestamp: "Long"
            };
            

            var n = new Notification(e.title, options);

            n.onclick = function(){
                window.open("https://goldenpsapp.com/api/events/" + e.id);
            }

            setTimeout(n.close.bind(n), 1800000);
                  


        },

        ForgotPassword(){

             Swal.fire({
                title: '<strong>Recuperar Contraseña</strong>',
                input: 'email',
                inputPlaceholder: 'Correo Eletrónico',
                html:
                  'Ingrese su correo electrónico para recuperar la clave, ' +
                  'será enviado un correo con instrucciones para el restablecimiento ' +
                  'de la contraseña.',
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonColor: '#7367f0',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Si, Eliminar!',
                icon: "warning"
        
              }).then((result) => {

                if (result.value != null) {

                    Swal.fire(
                        '¡Correo Enviado!',
                        'Un correo electrónico ha sido enviado a la dirección "' + result.value + '" para restablecer la contraseña.',
                        'success',
                        5000,
                    )

                    // Axios.post('password/reset', {  // URL Web Routes Laravel 
                    //     email: result.value
                    // })
                    // .then(function (response) { // Sweet Success
                    //     console.log(response)
                                                
                    // })
                    // .catch(function (error) {
                    //     console.log(error)
                    // })
                           
                }
                
              })
            
        },

        capitalize(data) {
            return data.charAt(0).toUpperCase() + data.slice(1).toLowerCase();
        },

        printer(data){

            console.log(data);

        },

        refreshingBell(data){

            console.log('Refreshing Bell');
            
            var div = $("#bell_link");
            div.fadeOut('slow')

            if (data == 'new') {

                if ($('#bell_num').text() != '') {
    
                    var num = $("#bell_num").text();
                    num = parseInt(num) + 1;
                    $('#bell_num').text(num);
                    $('#notifications').text(num + " Notificaciones");

                } else {
                    
                    $("#bell_link").append('<span class="badge badge-pill badge-primary badge-up" id="bell_num">1</span>');

                }

            } else {
    
                var num = $("#bell_num").text();
                num = parseInt(num) - 1;
                $('#bell_num').text(num);
                $('#notifications').text(num  + " Notificaciones");
        
            }


            
            console.log('Numero de eventos actual ' + num);

            div.fadeIn('slow')
            
        },

        addNotification(data){
            console.log('Receiving Data from addNotification...');
            console.log(data);
        },

        getAxios(url){

            Axios.get(url).then(function(response) {
                console.log(response.data);
            }).catch(function(e) {
                console.log(e);
            })

        },

        getNotifications(){

            Axios.post('api/getNotifications', {user_id : this.user}).then(function(response) {
                
                let data = response.data.data;

                $("#notification-content").remove();
                $('#notification-wraper').append('<div id="notification-content" class="col-12"></div>');   

                for (let i = 0; i < data.length; i++) {

                    let title = 'SIN TITULO';

                    if (data[i].title !== null) {
                        title = data[i].title;
                    }

                    $('#notification-content').append(
                    `<a  id="${data[i].id}">
                        <div class="media d-flex align-items-start pb-1 border-bottom">
                            <div class="media-left mt-1 ml-1">
                                <i class="feather icon-send font-medium-5 primary"></i>
                            </div> 
                            <div class="media-body">
                                <h6 class="primary media-heading mt-1">${title}</h6> 
                                <small class="notification-text" style="line-height: 150%;">${data[i].observation}</small>
                                <span class="text-primary"></span>
                            </div> 
                            <small class="mt-1 text-right"><time class="media-meta mr-1">${data[i].created_at}</time></small>
                        </div>
                    </a>`
                    );   

                    document.getElementById(data[i].id).addEventListener("click", function(){

                        app.ShowAlert(data[i].id);

                    }, false);

                    
                }
                    

            }).catch(function(e) {

                console.log(e);
                
            })

        },        
        
    }
   
});


